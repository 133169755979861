import React from "react"
import BackgroundImage from "gatsby-background-image"
import { navigate } from "gatsby"

import "./ImageButton.css"

const ImageButton = ({ text, link, image }) => {
  return (
    <div className="ImageButtonContainer" onClick={() => navigate(link)}>
      <BackgroundImage
        Tag="section"
        fluid={image}
        className="ButtonBackgroundImage"
        backgroundColor="#040e18"
      />
      <div className="ButtonActionBox">
        <h4>{text}</h4>
      </div>
      <div className="ImageButtonFilter" />
    </div>
  )
}

export default ImageButton
