import React from "react"
import Slider from "react-slick";
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import "./JoinUsTestimonial.css"

const JoinUsTestimonial = ({ heading, text, image }) => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "carouselJoinUs"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                teamMemberTestimonials {
                  heading
                  text
                  image {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  var carouselSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const TMtestimonial = ({ heading, text, image }) => {
    return (
      <div className="JoinUsSliderItemContainer">
        <div className="JoinUsTestimonialContainer">
          <div className="JoinUsTestimonialTextContainer">
            <h3>{heading}</h3>
            <p>{text}</p>
          </div>
          <div className="JoinUsTestimonialImageContainer">
            <Img fluid={image} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="JoinUsSliderContainer">
      <Slider {...carouselSettings}>
        {data.teamMemberTestimonials.map(T => (
          <TMtestimonial
            heading={T.heading}
            text={T.text}
            image={T.image.childImageSharp.fluid}
          />
        ))}
      </Slider>
    </div>
  )
}

export default JoinUsTestimonial
