import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ImageButton from "../ImageButton"

import "./JoinUsImageButtons.css"

export const Button1Link = "/jobdescription2.pdf"
export const Button2Link = "/jobdescription1.pdf"

const JoinUsImageButtons = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "joinus"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                academyJobDescriptionButton {
                  text
                  backgroundImage {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  file {
                    publicURL
                  }
                }
                schoolJobDescriptionButton {
                  text
                  backgroundImage {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  file {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <div className="JoinUsImageButtonsContainer">
      <div className="JoinUsImageButtons">
        <ImageButton
          text={data.academyJobDescriptionButton.text}
          link={data.academyJobDescriptionButton.file.publicURL} 
          image={data.academyJobDescriptionButton.backgroundImage.childImageSharp.fluid}
        />
        <ImageButton
          text={data.schoolJobDescriptionButton.text}
          link={data.schoolJobDescriptionButton.file.publicURL} 
          image={data.schoolJobDescriptionButton.backgroundImage.childImageSharp.fluid}
        />
      </div>
    </div>
  )
}

export default JoinUsImageButtons
