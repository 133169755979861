import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./JoinUsQuote.css"

const JoinUsQuote = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "joinus"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                paragraph2Image {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <img className="QuoteImage" src={data.paragraph2Image.publicURL} alt="Image of a motivational quote." />
  )
}

export default JoinUsQuote
