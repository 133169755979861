import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroBox from "../components/HeroBox"
import LayoutInner from "../components/LayoutInner"
import Description from "../components/Description"
import FullWidthBar from "../components/FullWidthBar"
import JoinUsImageButtons from "../components/JoinUsImageButtons"
import JoinUsQuote from "../components/JoinUsQuote"
import JoinUsTestimonial from "../components/JoinUsTestimonial"
import ContactBar from "../components/ContactBar"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "joinus"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              headerTitle
              headerSubtitle
              paragraph1Heading
              paragraph1Text
              paragraph2Heading
              paragraph2Text
            }
          }
        }
      }
    }
  }
`

const JoinUsPage = ( props ) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <Seo title="Join Us" />
      <HeroBox heading={data.headerTitle} text={data.headerSubtitle} />
      <LayoutInner>
        <Description heading={data.paragraph1Heading} text={data.paragraph1Text} smallHeading={true} />
        <JoinUsImageButtons />
        <FullWidthBar>
          <Description heading={data.paragraph2Heading} text={data.paragraph2Text} smallHeading={true} />
          <JoinUsQuote />
        </FullWidthBar>
        <JoinUsTestimonial />
      </LayoutInner>
      <ContactBar />
    </Layout>
  )
}

export default JoinUsPage
